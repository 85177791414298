import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {PlanBadge, Paginator, Select} from '../dsb.js';
import Layout from '../components/layout';
import numeral from 'numeral';
import { BeakerIcon, BuildingStorefrontIcon, ChartBarIcon, UsersIcon, MagnifyingGlassIcon, CheckIcon, XMarkIcon} from '@heroicons/react/24/solid'

export default function Products() {

	const {authFetch} = useAuthRequest();



	const navigate = useNavigate();

	const [searchParams, setSearchParams] = useSearchParams();

	const queryRef = useRef();
	const deletedRef = useRef();

	const [products, setProducts] = useState(null);

	const loadProducts = async() => {

		const page = searchParams.has('page')?`page=${searchParams.get('page')}&`:'';

		const q = searchParams.has('q')?`q=${searchParams.get('q')}`:'';
		const deleted = searchParams.has('deleted')?'&deleted=1':'';

		const response = await authFetch(`/api/admin/products?${page}${q}${deleted}`);

		const {products} = response;

		setProducts(products);

		window.scrollTo(0,0);
	}

	const submitSearch = (e) => {
		e.preventDefault();
		const q = queryRef.current.value;

		const deleted = deletedRef.current.checked;

		var deletedString = '';

		if (deleted=='1') {
			deletedString = '&deleted=1'
		}

		if (q == '') {
			navigate(`/products${deletedString}`);
		} else {
			navigate(`/products?q=${q}&page=1${deletedString}`);
		}

		return false;
	}

	const changePage = (page) => {
		const url = `/products?${searchParams.has('q')?`q=${searchParams.get('q')}&`:''}page=${page}&deleted=${searchParams.has('deleted')?'1':'0'}`;

		navigate(url);
	}

	useEffect(() => {
		loadProducts();
	}, [searchParams]);

	return <Layout current="products">
		<>
				
				<h3 className="text-white text-4xl font-bold pt-4 mb-4">Products</h3>
				<div className="mb-4">
					<form onSubmit={submitSearch}>
						<div className="flex shadow-lg mb-4">
							<input ref={queryRef} defaultValue={searchParams.get('q')} type="text" className="text-sm bg-white px-4 py-3 rounded-l-md w-full block" placeholder="Search by product title, sku, ID"/>
							<button className="bg-indigo-600 font-bold text-sm px-6 py-3 rounded-r-md"><MagnifyingGlassIcon className="text-white w-5 h-5"/></button>
						</div>
						<div className="flex items-center space-x-2">
							<input type="checkbox" defaultChecked={searchParams.get('deleted')} className="h-5 w-5" name="deleted" value="1" ref={deletedRef} id="deleted" />
							<label htmlFor="deleted" className="text-sm text-white">Show Deleted Products</label>
						</div>
					</form>

				</div>
				<div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
					{
						products!=null&&products.data.map(product => <Link to={`/products/${product.id}`} className="relative rounded-md bg-slate-600 hover:bg-slate-500 p-4" key={`product-${product.id}`}>
								{product.deleted_at==null?(product.image!=null?<img src={product.image.src} className="mb-1 block bg-slate-400 rounded-md object-contain aspect-square w-full"/>:<div className="mb-1 block bg-slate-400 rounded-md aspect-square w-full">&nbsp;</div>):
									<div className="mb-1 block bg-slate-400 flex items-center justify-center rounded-md aspect-square w-full"><span className="text-3xl font-thin font-sans tracking-widest opacity-30">DELETED</span></div>
								}
								{product.min_price==product.max_price?<span className="shadow-lg bg-lime-400 text-black text-xs font-bold px-3 rounded-full py-2 absolute top-5 right-5">{numeral(product.min_price).format('$0,0.00')}</span>:<span className="shadow-lg bg-lime-400 text-black text-xs font-bold px-3 rounded-full py-2 absolute top-5 right-5">{numeral(product.min_price).format('$0,0.00')} - {numeral(product.max_price).format('$0,0.00')}</span>}
								<div className="">
									<h3 className=" font-bold text-white">{product.title} 
									</h3>
									<p className="text-sm text-slate-400">{product.variants.length} {product.variants.length==1?'variant':'variants'}</p>
								</div>
							</Link>)
					}
				</div>
				{products!=null&&<div className="my-3">
					<Paginator data={products} changePage={changePage}/>
				</div>}
		</>
	</Layout>
}