import React, {useEffect, useState, useRef} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {PlanBadge, Paginator, Select, Label, Toast} from '../dsb.js';
import Layout from '../components/layout';
import numeral from 'numeral';
import { BeakerIcon, BuildingStorefrontIcon, ShoppingBagIcon, ChartBarIcon, UsersIcon, MagnifyingGlassIcon, CheckIcon, XMarkIcon} from '@heroicons/react/24/solid'

export default function Product() {

	const {authFetch} = useAuthRequest();

	const navigate = useNavigate();

	const {id} = useParams();

	const [product, setProduct] = useState(null);

	const [storeCount, setStoreCount] = useState(0);

	const [orderCount, setOrderCount] = useState(0);

	const [revenue, setRevenue] = useState(0);

	const [message, setMessage] = useState(false);

	const loadProduct = async() => {

		const response = await authFetch(`/api/admin/products/${id}`);

		const {product, revenue, store_count, order_count} = response;

		setProduct(product);

		setRevenue(revenue);

		setStoreCount(store_count);

		setOrderCount(order_count);

		window.scrollTo(0,0);
	}

	const syncFromShopify = async() => {
		const formData = new FormData();
		formData.append('ajax', 'true');
		const response = await authFetch(`/api/admin/products/${id}/sync`, 'POST', formData);

		const {message, error} = response;

		if (message) {
			loadProduct();
			setMessage(message);
		}

		if (error) {
			setMessage(error);
		}
	}

	useEffect(() => {
		loadProduct();
	}, []);

	return <Layout current="products">
		{product!=null&&<div className="pb-20 pt-8">

			<section className="grid xl:grid-cols-6">
				<div className="xl:col-span-4 space-y-4">
			<div className="flex space-x-4 mb-4 ">
				{product.deleted_at!=null&&<div className="grow-0 bg-slate-400 aspect-square w-32 h-32 object-contain rounded-md flex items-center justify-center"><span className="text-xl font-sans font-thin tracking-widest opacity-30">DELETED</span></div>}
				{product.deleted_at==null&&(product.image?<img src={product.image.src} className="grow-0 bg-slate-400 aspect-square w-32 h-32 object-contain rounded-md"/>:<div className="grow-0 bg-slate-400 aspect-square w-32 h-32 object-contain rounded-md flex items-center justify-center"><ShoppingBagIcon className="w-12 h-12 text-slate-500/50"/></div>)}
				<div className="grow truncate">
					<h3 className="text-white text-4xl font-bold mb-1 block truncate">{product.title}</h3>
					<p className="text-slate-400 text-xs mb-2">Created {moment(product.created_at).fromNow()} <span className="text-slate-500">&middot;</span> Last updated {moment(product.updated_at).fromNow()}</p>
					<div className="grid grid-cols-3 gap-4">
						<div className="rounded-md bg-slate-800 px-4 py-2">
							<h3 className="text-xl font-bold text-white">{numeral(revenue).format('$0,0.00')}</h3>
							<p className="text-xs font-bold text-slate-400">Revenue (Past Year)</p>
						</div>
						<div className="rounded-md bg-slate-800 px-4 py-2">
							<h3 className="text-xl font-bold text-white">{numeral(orderCount).format('0,0')}</h3>
							<p className="text-xs font-bold text-slate-400">Orders (Past Year)</p>
						</div>
						<div className="rounded-md bg-slate-800 px-4 py-2">
							<h3 className="text-xl font-bold text-white">{numeral(storeCount).format('0,0')}</h3>
							<p className="text-xs font-bold text-slate-400">Store Count</p>
						</div>

					</div>
				</div>
				
			</div>
				<div className="rounded-md bg-slate-800">
					<div className="px-4 py-2 border-b border-slate-700 flex items-center justify-between">
						<h4 className="text-white text-lg font-bold">Product Details</h4>
						{product.deleted_at==null&&<a href={`https://beautystorefront.myshopify.com/admin/products/${product.id}`} className="text-cyan-400 text-sm hover:text-cyan-500" target="_blank">Edit in Beauty Storefront</a>}
					</div>
					<div className="px-4 pt-3 pb-6">
						
						<Label >Description</Label>
						<div className="h-64 overflow-auto bg-slate-700 text-slate-300 mt-1 px-4 text-sm py-3 border-0 rounded-md" dangerouslySetInnerHTML={{__html: product.body_html}}/>
					</div>
				</div>

				<section className="bg-slate-800 rounded-md ">
						<div className="px-4 py-2 border-b border-slate-700 flex items-center justify-between">
						<h4 className="text-white text-lg font-bold">Variants</h4>
						</div>
						<div>
						<table className="w-full">
						<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
					<tr>
						<th className=" px-4 py-3">ID</th>
						<th className="px-4 py-3">Option</th>
						<th className=" px-4 py-3">SKU</th>
						<th className=" px-4 py-3 text-center">Inventory</th>
						<th className=" px-4 py-3 text-center">Last Update</th>
						
					</tr>
				</thead>
							<tbody className="divide-y divide-slate-700 w-full  text-sm text-gray-200">
							{
								product.variants.map(variant => <tr className="w-full " key={`order-${variant.id}`}>
										<td className="px-4 py-3"><span>{variant.id}</span></td>
										<td className="px-4 py-3"><span>{variant.option_1}</span></td>

										<td className=" px-4 py-3"><span>{variant.sku}</span></td>
										<td className=" px-4 py-3 text-center"><span>{numeral(variant.quantity).format('0,0')}</span></td>
										
										<td className=" px-4 py-3 text-right"><span>{moment(variant.updated_at).fromNow()}</span></td>
										
									</tr>)
							}
							</tbody>
						</table>
						</div>
					</section>
					<section className="bg-slate-800 rounded-md ">
						<div className="px-4 py-2 border-b border-slate-700 flex items-center justify-between">
						<h4 className="text-white text-lg font-bold">Recent Changes</h4>
						</div>
						<div>
						<table className="w-full">
						<thead className="text-left border-b border-slate-700 text-slate-300 text-xs uppercase">
							<tr>
								<th className=" px-4 py-3">Variant</th>
								<th className="px-4 py-3">Change</th>
								<th className=" px-4 py-3 text-center">Updated</th>
								
							</tr>
						</thead>
						<tbody className="divide-y divide-slate-700 w-full  text-sm text-gray-200">
						{
							product.changes.map(change => <tr className="w-full " key={`change-${change.id}`}>
									<td className="px-4 py-3">
										<span>{change.variant.option_1} &middot; {change.variant.sku}</span>
									</td>

									<td className=" px-4 py-3">
										{
											change.new_count!=null&&(change.new_count>change.old_count?<span>Inventory <span className="text-lime-400">increased</span> from {numeral(change.old_count).format('0,0')} to {numeral(change.new_count).format('0,0')}</span>:<span>Inventory <span className="text-indigo-400">decreased</span> from {numeral(change.old_count).format('0,0')} to {numeral(change.new_count).format('0,0')}</span>)
										}
										{
											change.new_price!=null&&(change.new_price>change.old_price?<span>Price <span className="text-lime-400">increased</span> from {numeral(change.old_price).format('$0,0.00')} to {numeral(change.new_price).format('$0,0.00')}</span>:<span>Price <span className="text-indigo-400">decreased</span> from {numeral(change.old_price).format('$0,0.00')} to {numeral(change.new_price).format('$0,0.00')}</span>)
										}
									</td>
									
									<td className=" px-4 py-3 text-right"><span>{moment(change.created_at).fromNow()}</span></td>
									
								</tr>)
						}
						</tbody>
						</table>
						</div>
					</section>

					{product.deleted_at==null&&<section>
						<button onClick={() => syncFromShopify()} className="bg-indigo-600 px-4 py-3 rounded-md text-sm font-bold text-white">Sync from Beauty Storefront</button>
					</section>}

				</div>

				<div className="col-span-2">

				</div>
			</section>
		</div>}
		<Toast message={message} onReset={() => setMessage(false)}/>
	</Layout>
}