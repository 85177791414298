import React, {createContext, useContext} from 'react';
import { redirect } from "react-router-dom";
import { useCookies, Cookies } from 'react-cookie';

const AuthContext = createContext();

export function useAuthRequest() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthRequest must be used within a AuthProvider");
  }

  return context;
}


export const AuthProvider = ({ children }) => {

  //const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies(['_token']);

  const authed = async() => {


    if (!cookies._token) {
      return false;
    }

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${cookies._token}`
      }
    };

    const url = `${process.env.REACT_APP_ENDPOINT}/api/auth`;

    const response = await fetch(url, options);

    if (response.status == 401) {
      return false;
    }

    const user = await response.json();


    if (user && user.admin == 0) {
      return false;
    }

    return true;


  }
  
  const authFetch = async(url, method='GET', body=null, multipart=false) => {

    const currentUri = document.location.href;

    if (!cookies._token) {
      setCookie('redirect_uri', currentUri, {
        path: '/'
      });

      //alert('here');
      window.location.href = '/login';
      
    }

    // console.log(cookies.token);
  	const options = {
  		method,
  		headers: {
  			Accept: 'application/json'
  		}
  	};

    if (multipart == true) {
      options.headers['Content-Type'] = 'multipart/form-data';
    }

  	if (method != 'GET' && body != null) {
  		options['body'] = body;
  	}

  	if (cookies._token) {
  		options['headers']['Authorization'] = `Bearer ${cookies._token}`;
  		;
  	}

    const domain = process.env.NODE_ENV=='production'?'dropshipbeauty.app':'dropshipbeauty.xyz';

    const absoluteUrl = `https://${domain}${url}`;

  	const response = await fetch(absoluteUrl, options);

  	if (response.status == 500) {
  		return {error: 'An error has occurred'};
  	}

  	if (response.status == 401) {
      
      setCookie('redirect_uri', currentUri, {
        path: '/'
      });

     // return redirect('/admin/login');
  	}

  	return response.json();
  }	

  return (
    <AuthContext.Provider value={{authFetch, authed}}>
        {children}
    </AuthContext.Provider>
  );
};