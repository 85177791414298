import React, {useEffect, useCallback, useState, useRef} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import axios from 'axios';
import {PlanBadge, OrderStatus, SetupStatus, MenuButton, Modal, Toast, Button, Card, Label, Select} from '../dsb.js';
import numeral from 'numeral';
import Layout from '../components/layout';
import { useDropzone } from "react-dropzone";
import { BeakerIcon, ArrowPathIcon, TrashIcon, BuildingStorefrontIcon,  PencilSquareIcon, ChartBarIcon, UsersIcon, MagnifyingGlassIcon, CheckIcon, XMarkIcon} from '@heroicons/react/24/solid'

export default function EditDownload() {

	const {id} = useParams();

	const {authFetch} = useAuthRequest();

	const [download, setDownload] = useState(null);

	const [selectedImages, setSelectedImages] = useState([]);

	const [existingImages, setExistingImages] = useState([]);

	const [restoreModal, setRestoreModal] = useState(false);

	const [description, setDescription] =  useState('');

	const [title, setTitle] = useState('');

	const [credits, setCredits] = useState('');

	const [disabled, setDisabled] = useState(false);

	const [status, setStatus] = useState('rest');

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
	    acceptedFiles.forEach((file) => {
	      setSelectedImages((prevState) => [...prevState, file]);
	    });
	  }, []);

	const {
	    getRootProps,
	    getInputProps,
	    isDragActive,
	    isDragAccept,
	    isDragReject,
	  } = useDropzone({onDrop});

	  const init = async() => {
	  	const response = await authFetch(`/api/admin/photosets/${id}`);

	  	if (response.download) {
	  		setDownload(response.download);
	  		setTitle(response.download.title);
	  		setCredits(response.download.credits);
	  		setDescription(response.download.description);
	  		setExistingImages(response.download.images);
	  	}

	  }

	  const submitPhotoSet = async() => {

	  		if (titleRef.current.value.trim() == '') {
	  			setMessage('Title is required');
	  			return false;
	  		}

	  		if (creditsRef.current.value == '' || parseInt(creditsRef.current.value) < 1 ) {
	  			setMessage('Number of credits required and must be larger than 0');
	  			return false;
	  		}

	  		if (selectedImages.length == 0 && existingImages.length == 0) {
	  			setMessage('Photos are required.');
	  			return false;
	  		}

	  	  setDisabled(true);

	  	  const sig = await authFetch(`/api/admin/cloudinary-sig`);

	      const formData = new FormData();

	      const formData2 = new FormData();

	      selectedImages.forEach((image) => {
	        formData.append("file", image);
	      });

	 	
	      const thumbnailIds = [];
	      const publicIds = [];
	      const existingIds = [];

          const thumbPromises = selectedImages.map(image => {
            const formData = new FormData();
            formData.append('file', image);
            formData.append('upload_preset', 'thumbnail'); // Replace with your signed preset name
            formData.append('timestamp', sig.timestamp);
            formData.append('signature', sig.thumbnail_signature);
            formData.append('api_key', sig.key);

            return axios.post('https://api.cloudinary.com/v1_1/wigmarket/image/upload', formData)
                .then(response => response.data)
                .catch(error => console.error('Error uploading image:', error));
        });

          setStatus('thumbnails');

          const thumbnails = await Promise.all(thumbPromises);

	      const uploadPromises = selectedImages.map(image => {
            const formData = new FormData();
            formData.append('file', image);
            formData.append('upload_preset', 'photo-set-upload'); // Replace with your signed preset name
            formData.append('timestamp', sig.timestamp);
            formData.append('signature', sig.signature);
            formData.append('api_key', sig.key);

            return axios.post('https://api.cloudinary.com/v1_1/wigmarket/image/upload', formData)
                .then(response => response.data)
                .catch(error => console.error('Error uploading image:', error));
        });

	     setStatus('uploading');

	    const publicImages = await Promise.all(uploadPromises);

	    publicImages.forEach(image => {
	    	thumbnails.forEach(thumbnail => {
	    		if (thumbnail.original_filename == image.original_filename) {
	    			publicIds.push(image.public_id);
	    			thumbnailIds.push(thumbnail.public_id);
	    		}
	    	});
	    });

	    existingImages.forEach(existing => {
	    	existingIds.push(existing.asset_id);
	    });

	    formData2.append('title', titleRef.current.value);

	      formData2.append('description', descriptionRef.current.value);

	      formData2.append('credits', creditsRef.current.value);

	      formData2.append('public_ids', publicIds);

	      formData2.append('thumbnail_ids', thumbnailIds);

	      formData2.append('existing_ids', existingIds);

	      setStatus('saving');

	    const response = await authFetch(`/api/admin/photosets/${id}`, 'POST', formData2);

	    if (response.error) {
	    	setMessage(response.error);
	    	setStatus('rest');
	    }

	    if (response.message) {
	    	setMessage(response.message);
	    	if (response.url) {
	    		setTimeout(() => {
	    			document.location.href = response.url;
	    		}, 2000);
	    	}
	    }


	  };	

	const navigate = useNavigate();

	const [message, setMessage] = useState(false);

	const [deleteModal, setDeleteModal] = useState(false);

	const titleRef = useRef();

	const descriptionRef = useRef();

	const creditsRef = useRef();

	const deleteDownload = async() => {
		const response = await authFetch(`/api/admin/photosets/${id}/delete`, 'POST');

		if (response.error) {
			setMessage(response.error);
		}

		if (response.message) {
			setMessage(response.message);
		}

		setDeleteModal(false);
	}

	const restoreDownload = async() => {
		const response = await authFetch(`/api/admin/photosets/${id}/restore`, 'POST');

		if (response.error) {
			setMessage(response.error);
		}

		if (response.message) {
			setMessage(response.message);
		}

		if (response.download) {
			setDownload(response.download);
		}

		setRestoreModal(false);
	}

	const deleteImage = (index) => {

		const images = selectedImages.slice(0);
		images.splice(index, 1);

		setSelectedImages(images);
	}

	const deleteExistingImage = (index) => {
		const images = existingImages.slice(0);
		images.splice(index, 1);
		setExistingImages(images);
	}


	useEffect(() => {
		init();
	}, []);

	return <Layout current="downloads">
			<div className="">
				<h1 className="text-white font-bold text-4xl my-8">{title}</h1>
				<div className="bg-slate-800 rounded-md mt-4 px-4 py-3 mb-4 space-y-4">
					<div className="md:grid md:grid-cols-2 gap-4">
						<div>
							<label className="text-white mb-1 text-sm font-bold block">Title *</label>
							<input value={title} onChange={e => setTitle(e.target.value)} type="text" ref={titleRef} className="bg-slate-600 text-slate-100 rounded px-4 py-3 text-sm block w-full" placeholder=""/>
						</div>
						<div>
							<label className="text-white mb-1 text-sm font-bold block">Credits</label>
							<input value={credits} onChange={e => setCredits(e.target.value)} ref={creditsRef} className="bg-slate-600 text-slate-100 rounded px-4 py-3 text-sm block w-full" type="number" placeholder="1"/>
						</div>

					</div>
					<div>
						<label className="text-white mb-1 text-sm font-bold block">Description</label>
						<textarea value={description} onChange={e => setDescription(e.target.value)} rows="3" ref={descriptionRef} className="bg-slate-600 text-slate-100 rounded px-4 py-3 text-sm block w-full" placeholder="Describe this photo set"/>
					</div>
					<div className="border mb-8 border-slate-600 bg-slate-600 px-8 py-10 text-slate-100 text-center text-sm rounded-md">
				      <div className="" {...getRootProps()}>
				        <input {...getInputProps()} />
				        {isDragActive ? (
				          <p className="text-white">Drop file(s) here ...</p>
				        ) : (
				          <p className=" text-slate-100">Drag and drop file(s) here, or click to select files</p>
				        )}
				      </div>
				    </div>
				    <div className="flex flex-wrap ">
				    	{
				    		existingImages.map((image, index) => <div className="relative" key={`image-${image.id}`}>
				    				<button onClick={() => deleteExistingImage(index)} className="hover:bg-red-700 bg-red-600 rounded px-2 py-2 absolute -top-2 -right-2"><TrashIcon className="text-white h-3 w-3 fill-white"/></button>
				    				<img src={`//res.cloudinary.com/wigmarket/${image.thumbnail_id}.jpg`}  className="w-48 object-cover h-48 aspect-square m-4"/>
				    			</div>)
				    	}
				    	{selectedImages.length > 0 &&
				          selectedImages.map((image, index) => (
				          	<div className="relative" key={image.name}>
				          		<button onClick={() => deleteImage(index)} className="hover:bg-red-700 bg-red-600 rounded px-2 py-2 absolute -top-2 -right-2"><TrashIcon className="text-white h-3 w-3 fill-white"/></button>
				            	<img src={`${URL.createObjectURL(image)}`} alt="" className="w-48 object-cover h-48 aspect-square m-4"/>
				          	</div>
				          ))}
				          {

				          }
				    </div>


				    {
				    	status=='thumbnails'&&<div className="text-white flex items-center space-x-4"><ArrowPathIcon className="animate-spin w-5 h-5"/><span>Generating Thumbnails...</span></div>
				    }
				    {
				    	status=='uploading'&&<div className="text-white flex items-center space-x-4"><ArrowPathIcon className="animate-spin w-5 h-5"/><span>Uploading Full Sized Images...</span></div>
				    }
				    {
				    	status=='saving'&&<div className="text-white flex items-center space-x-4"><ArrowPathIcon className="animate-spin w-5 h-5"/><span>Generating Archive and Saving...</span></div>
				    }
				    {download!=null&&<div className="space-x-2">
					    {download.deleted_at==null&&<button disabled={status!='rest'} className="disabled:opacity-50 px-4 py-3 bg-black text-white" onClick={() => submitPhotoSet()} type="button">Submit</button>}
						{download.deleted_at==null&&<button className="bg-rose-600 text-white px-4 py-3" onClick={() => setDeleteModal(true)}>Delete</button>}
						{download.deleted_at!=null&&<button className="bg-rose-600 text-white px-4 py-3" onClick={() => setRestoreModal(true)}>Restore</button>}
					</div>}
				</div>
			</div>
			<Toast message={message} onReset={() => setMessage(false)} duration={3500}/>
			<Modal title="Are you sure you want to delete this download?" primary={{action: deleteDownload, label: 'Delete'}} secondary={{action: () => setDeleteModal(false), label: 'Cancel'}} close={() => setDeleteModal(false)} open={deleteModal}>
				<p className="text-white">Photo Downloads can be restored after deletion.</p>
			</Modal>
			<Modal title="Are you sure you want to restore this download?" primary={{action: restoreDownload, label: 'Restore'}} secondary={{action: () => setRestoreModal(false), label: 'Cancel'}} close={() => setRestoreModal(false)} open={restoreModal}>
				<p className="text-white">Restored downloads will be immediately available in the Shopify app.</p>
			</Modal>
	</Layout>;
}