import React, {useEffect, useState} from 'react';
import {
  Link,
  useParams,
  useRouteMatch,
  useNavigate,
  Outlet,
  useSearchParams
} from "react-router-dom";
import {useCookies} from 'react-cookie';
import {useAuthRequest} from '../auth.js';
import moment from 'moment';
import {PlanBadge} from '../dsb.js';
import { BeakerIcon, PhotoIcon, MegaphoneIcon, ArrowLeftOnRectangleIcon, TagIcon, ShoppingCartIcon, Bars3Icon, BuildingLibraryIcon, LockClosedIcon, BuildingStorefrontIcon, InboxIcon, TruckIcon, ChartBarIcon, UsersIcon, MagnifyingGlassIcon, CheckIcon, XMarkIcon} from '@heroicons/react/24/solid'

const links = [
	{
		id: 'dashboard',
		url: '/dashboard',
		name: 'Dashboard',
		icon: <ChartBarIcon className="w-5 h-5"/>
	},
	{
		id: 'stores',
		url: '/stores',
		name: 'Stores',
		icon: <BuildingStorefrontIcon className="w-5 h-5"/>
	},
	{
		id: 'setups',
		url: '/setups/pending',
		name: 'Setup Requests',
		icon: <UsersIcon className="w-5 h-5"/>
	},
	{
		id: 'branding',
		url: '/custom-branding-requests',
		name: 'Custom Branding',
		icon: <InboxIcon className="w-5 h-5"/>
	},
	{
		id: 'orders',
		url: '/orders?status=pending',
		name: 'Orders',
		icon: <TruckIcon className="w-5 h-5"/>
	},
	{
		id: 'products',
		url: '/products',
		name: 'Products',
		icon: <ShoppingCartIcon className="w-5 h-5"/>
	},
	{
		id: 'collections',
		url: '/collections',
		name: 'Collections',
		icon: <TagIcon className="w-5 h-5"/>
	},
	{
		id: 'downloads',
		url: '/downloads',
		name: 'Photo Downloads',
		icon: <PhotoIcon className="w-5 h-5"/>
	},
	{
		id: 'content',
		url: '/content',
		name: 'Announcements',
		icon: <MegaphoneIcon className="w-5 h-5"/>
	},
	{
		id: 'users',
		url: '/users',
		name: 'Admin Users',
		icon: <BuildingLibraryIcon className="w-5 h-5"/>
	},
	{
		id: 'password',
		url: '/password',
		name: 'User Settings',
		icon: <LockClosedIcon className="w-5 h-5"/>
	},
];

export default function Layout({children, current=''}) {

	const [cookies, setCookies, removeCookie] = useCookies();

	const navigate = useNavigate();

	const [menuOpen, setMenuOpen] = useState(false);

	const signOut = () => {
		removeCookie('_token');
		navigate('/');
	}

	return <main className="min-h-screen flex-col bg-gray-800">
		<div className={`${menuOpen?'fixed':'relative'} w-full bg-gray-800 z-50 px-6 py-4 border-b border-slate-700`}>
			<Link to="/" className="hidden sm:block"><img src="https://res.cloudinary.com/wigmarket/image/upload/v1680273284/dropship-beauty-logo_200x_2x_h9r4bf_oeynnl_nda6ep.png" className="w-24"/></Link>
			<button className="block sm:hidden text-white" onClick={() => setMenuOpen(!menuOpen)}>{menuOpen?<XMarkIcon className="w-8 h-8"/>:<Bars3Icon className="w-8 h-8"/>}</button>
		</div>
		<div className="sm:flex flex-1">
			<div className={`${menuOpen?'block':'hidden'} fixed md:relative left-0 h-full z-40 bg-black/90 md:block md:w-auto w-full sm:max-w-[20%] lg:max-w-[16%]  md:bg-gray-800 border-r border-slate-700`}>
				<label className="uppercase text-gray-500 font-bold text-xs block px-6 pt-2">MENU</label>
				<ul className="px-2 py-2">
					{
						links.map(link => <li key={`link-${link.id}`}><Link to={link.url} className={`${(current==link.id)&&'bg-slate-600'} hover:bg-gray-700 rounded-md text-white px-4 py-3 flex space-x-3 items-center`}>
								{link.icon}
								<span className="text-sm text-gray-200">{link.name}</span>
								</Link>
							</li>)
					}
					<li><button onClick={() => signOut()} className="hover:bg-gray-700 w-full rounded-md text-white px-4 py-3 flex space-x-3 items-center"><ArrowLeftOnRectangleIcon className="w-5 h-5"/><span className="text-sm text-gray-200">Sign out</span></button></li>
				</ul>
			</div>
			<div className="sm:flex-1 bg-gray-700 min-h-screen">
			<div className=" lg:max-w-8xl mx-4 md:mx-8">
				{children}
			</div>
			</div>
		</div>
		</main>
}